import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/appointments-dashboard',
      name: 'appointments-dashboard',
      component: () => import('@/views/dashboard/Appointments/Appointments.vue'),
      meta: {
        pageTitle: 'Appointments DashBoard',
        requiresAuth: true,
      },
    },
    {
      path: '/appointments-details',
      name: 'appointments-details',
      component: () => import('@/views/dashboard/DetailsAppointments/DetailsAppointments.vue'),
      meta: {
        pageTitle: 'Appointments Details',
        requiresAuth: true,
      },
    },
    {
      path: '/representatives-dashboard',
      name: 'representatives-dashboard',
      component: () => import('@/views/dashboard/Reps/RepresentativesDetails.vue'),
      meta: {
        pageTitle: 'Representatives DashBoard',
        requiresAuth: true,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/activities',
      name: 'activities',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Activities',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/interactive-reporter/',
      name: 'interactive-reporter',
      component: () => import('@/views/reports/interactiveReport/InteractiveReport.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/reports/daily-appointment/',
      name: 'daily-appointment',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Daily Appointment',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Daily Appointment',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/daily-visit/',
      name: 'daily-visit',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Daily Visit',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Daily Visit',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/occurred-visits/',
      name: 'occurred-visits',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Occurred Visits',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Occurred Visits',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/canceled-visits/',
      name: 'canceled-visits',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Canceled Visits',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Canceled Visits',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/general/',
      name: 'general',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'General',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'General',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: '/leads',
      name: 'leads',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Leads',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/appointments',
      name: 'appointments',
      component: () => import('@/views/appointments/Appointment.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/crm',
      name: 'crm',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'CRM',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/catalogs',
      name: 'catalogs',
      component: () => import('@/views/settings/catalogs/Catalogs.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/settings/users/Users.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/users-profile',
      name: 'users-profile',
      component: () => import('@/views/settings/users/UserProfile.vue'),
      meta: {
        pageTitle: 'User Profile',
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/access-level',
      name: 'access-level',
      component: () => import('@/views/settings/accessLevel/AccessLevel.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/access-level-profile',
      name: 'access-level-profile',
      component: () => import('@/views/settings/accessLevel/AccessLevelProfile.vue'),
      meta: {
        pageTitle: 'Access Level Profile',
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/lists/roster-importer/',
      name: 'roster-importer',
      component: () => import('@/views/lists/roasterImporter/RoasterImporter.vue'),
      meta: {
        pageTitle: 'Roster Importer',
        breadcrumb: [
          {
            text: 'Lists',
          },
          {
            text: 'Roster Importer',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/lists/roaster-importer/roaster-configuration',
      name: 'roaster-configuration',
      component: () => import('@/views/lists/roasterConfiguration/RoasterConfiguration.vue'),
      meta: {
        pageTitle: 'Roaster Configuration',
        breadcrumb: [
          {
            text: 'Lists',
          },
          {
            text: 'Roaster Importer',
          },
          {
            text: 'Roaster Configuration',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/Profile.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/profile/kardex-profile/',
      name: 'kardex-profile',
      component: () => import('@/views/profile/kardexprofile/KardexProfile.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Dashboard',
      },
    },
    {
      path: '/two-factor-auth',
      name: 'two-factor-auth',
      component: () => import('@/views/TwoFactorAuth.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/dashboard-crm',
      name: 'dashboard-crm',
      component: () => import('@/views/DashboardCrm.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/users-logged',
      name: 'users-logged',
      component: () => import('@/views/UsersLogged.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/system/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const isLoggedIn = store.getters['user-auth/isLoggedIn']
    if (isLoggedIn) next()
    else next('/')
  } else {
    next()
  }
})

export default router
